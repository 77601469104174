<template>
    <div
        class="gift-sets"
        :class="{ 'receive-bg': page > 1 }"
        :style="{
            backgroundImage: 'url(' + bg_img + ')',
            backgroundSize: 'cover',
        }"
    >
        <!-- 商品内容展示 -->
        <div class="gift-sets-main">
            <!-- 商品内容 -->
            <div v-if="page == 1">
                <h2 class="gift-sets-title">请选择礼品套餐</h2>
                <div class="list-box">
                    <div class="tab-box">
                        <div
                            class="tab-box-item"
                            :class="{ active: cur_tab == item }"
                            v-for="(item, index) in tab_list"
                            :key="index"
                            @click="cur_tab = item"
                        >
                            <span>{{ item }}</span>
                        </div>
                    </div>
                    <div class="shop-box">
                        <div v-for="(item, index) in shop_list" :key="index">
                            <div
                                class="shop-list"
                                v-if="cur_tab == index"
                                :class="`item-${item.length}`"
                            >
                                <div
                                    class="shop-list-item"
                                    v-for="(child, i) in item"
                                    :key="i"
                                    @click="viewDetails(child)"
                                >
                                    <div
                                        class="shop-list-item-main"
                                        v-if="item.length <= 2"
                                    >
                                        <p class="title">{{ child.title }}</p>
                                        <img :src="child.cover" />
                                    </div>
                                    <div class="shop-list-item-main" v-else>
                                        <img :src="child.cover" />
                                        <p class="title">{{ child.title }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="submit-btn">
                            <button @click="selectItem()">确定</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 填写商品地址 -->
            <div class="send-address" v-if="page == 2">
                <van-swipe
                    v-if="pageType != 2"
                    class="my-swipe"
                    :autoplay="3000"
                    indicator-color="white"
                    @change="onChange"
                >
                    <van-swipe-item
                        v-for="(item, index) in shop_list[cur_tab]"
                        :key="index"
                    >
                        <img :src="item.cover" alt="" />
                    </van-swipe-item>
                </van-swipe>
                <div v-else class="send-address-img">
                    <div v-html="tips" v-if="tips"></div>
                </div>
                <!-- <h2 class="meal-title">套餐{{cur_tab}}</h2> -->
                <p class="meal-text" v-if="pageType != 2">
                    {{ shop_list[cur_tab][cur_index].title }}
                </p>
                <div class="send-tips">
                    <h3>
                        <span v-if="pageType != 2">恭喜您已选定礼品<br /></span
                        >请留下您的寄送信息
                    </h3>
                    <div class="from-table">
                        <input
                            type="text"
                            placeholder="收件人姓名"
                            v-model="name"
                        />
                        <input
                            type="text"
                            placeholder="收件人手机号码"
                            v-model="phone"
                        />
                        <textarea
                            placeholder="收货地址"
                            rows="3"
                            v-model="address"
                        ></textarea>
                    </div>
                    <div class="send-btns">
                        <button @click="page = 1" v-if="pageType != 2">
                            返回重新选择
                        </button>
                        <button @click="sendBtn()">提交</button>
                    </div>
                </div>
            </div>
            <!-- 已领 -->
            <div
                class="receive"
                :style="{ backgroundColor: bg_color }"
                v-if="page == 3"
            >
                <div class="receive-item">
                    <h3>收件人信息</h3>
                    <div>
                        <p>{{ name }}（{{ phone }}）</p>
                        <p>{{ address }}</p>
                    </div>
                </div>
                <div>
                    <div class="receive-item">
                        <h3>物流信息</h3>
                        <div v-if="express_name && express_no">
                            <p>{{ express_name }}（{{ express_no }}）</p>
                        </div>
                        <div v-else>
                            我们将根据您提交的地址，尽快安排寄送礼品，谢谢!
                        </div>
                    </div>
                    <van-steps
                        direction="vertical"
                        :active="0"
                        v-if="wuliuList && wuliuList.length > 0"
                        inactive-color="#ffffff"
                        :active-color="text_color"
                    >
                        <van-step
                            v-for="(item, index) in wuliuList"
                            :key="index"
                        >
                            <h3>{{ item.context }}</h3>
                            <p>{{ item.time }}</p>
                        </van-step>
                    </van-steps>
                </div>

                <!-- <van-cell-group v-if="show_success">
                    <van-cell v-if="name" title="姓名" :value="name" />
                    <van-cell v-if="phone" title="手机号" :value="phone" />
                    <van-cell v-if="address" title="收货地址" :value="address" />
                </van-cell-group> -->
                <!-- <van-steps direction="vertical" :active="0" v-if="wuliuList">
                    <van-step v-for="(item, index) in wuliuList" :key="index">
                        <h3>{{ item.context }}</h3>
                        <p>{{ item.time }}</p>
                    </van-step>
                </van-steps> -->
                <!-- <div class="gift-box">
                      <van-swipe
                        class="my-swipe"
                        :autoplay="3000"
                        indicator-color="white"
                        @change="onChange"
                        >
                            <van-swipe-item v-for="(item,index) in shop_list[cur_tab]" :key="index">
                                <img :src="item.cover" alt="">
                            </van-swipe-item>
                        </van-swipe>
                        <p class="meal-text">{{shop_list[cur_tab][cur_index].title}}</p>
                 </div> -->
            </div>
        </div>
        <!-- 提交成功 -->
        <!-- <van-popup v-model="show_success">
            <div class="send-success">
                <img src="@/assets/images/gift-success.jpg" />
            </div>
        </van-popup> -->
    </div>
</template>

<script>
/* eslint-disable */
export default {
    name: "new-year",
    data() {
        return {
            // 套餐菜单
            tab_list: [],
            // 当前选中菜单
            cur_tab: "A",
            // 菜单商品
            shop_list: {},
            // 当前序号
            cur_index: 0,
            // 套餐id
            id: this.$route.query.id ? this.$route.query.id : "",
            // 寄送地址
            name: "",
            // 寄送电话
            phone: "",
            // 寄送地址
            address: "",
            // 寄送成功
            show_success: false,
            // 步驟
            page: 0,
            // 背景图
            order_img: "",
            // 成功背景
            success_img: "",
            // 物流信息
            wuliuList: null,
            pageType: "1",
            bg_color: "rgba(91, 12, 12, 0.5)",
            express_name: "",
            express_no: "",
            text_color: "#efc871",
            tips: "",
        };
    },
    created() {
        document.title = "礼品.商标";
        this.getSetsInfo();
    },
    destroyed() {
        document.title = "礼品平台|公司业务网站|公司业务";
    },
    computed: {
        // 设置背景图
        bg_img() {
            return this.page == 3 ? this.success_img : this.order_img;
        },
    },
    methods: {
        // 获取套餐信息
        getSetsInfo() {
            const that = this;
            that.$axios
                .post("/v1/activities/info", {
                    id: that.id,
                })
                .then((res) => {
                    let _res = res.data.data;
                    if (_res.code === 1000) {
                        that.tab_list = Object.keys(_res.data.packages);
                        that.shop_list = _res.data.packages;
                        that.page = _res.data.state === "已兑换" ? 3 : 1;
                        that.pageType = _res.data.pageType;
                        that.express_name = _res.data.express_name;
                        that.express_no = _res.data.express_no;
                        if (_res.data.bg_color) {
                            that.bg_color = _res.data.bg_color;
                        }
                        if (_res.data.text_color) {
                            that.text_color = _res.data.text_color;
                        }
                        if (that.pageType == 2) {
                            that.page = 2;
                        }
                        that.order_img = _res.data.order_img;
                        that.success_img = _res.data.success_img;
                        that.wuliuList = _res.data.expressInfo;
                        if (_res.data.post_phone) {
                            that.phone = _res.data.post_phone;
                            this.show_success = true;
                        }
                        if (_res.data.post_name) {
                            that.name = _res.data.post_name;
                        }
                        if (_res.data.post_address) {
                            that.address = _res.data.post_address;
                        }
                        that.tips = _res.data.tips;
                    } else {
                        that.$toast({
                            message: _res.message ? _res.message : "操作失败",
                            duration: 2000,
                        });
                    }
                });
        },
        // 选择套餐
        selectItem() {
            this.page = 2;
        },
        // 当前商品
        onChange(index) {
            this.cur_index = index;
        },
        // 查看详情
        viewDetails(item) {
            this.$router.push({
                path: "/goodsContent",
                query: {
                    id: item.goods_guid,
                    fromActive: "/activities" + "?id=" + this.id,
                },
            });
        },
        sendBtn() {
            const that = this;
            let regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
            if (!that.name) {
                that.$toast({
                    message: "请输入您的姓名",
                    duration: 2000,
                });
                return false;
            } else if (!that.phone) {
                that.$toast({
                    message: "请输入您的手机号",
                    duration: 2000,
                });
                return false;
            } else if (!regMobile.test(that.phone)) {
                that.$toast({
                    message: "请输入正确手机号",
                    duration: 2000,
                });
                return false;
            } else if (!that.address) {
                that.$toast({
                    message: "请输入您的收货地址",
                    duration: 2000,
                });
                return false;
            }
            let guids = [];

            that.shop_list[that.cur_tab].forEach((item) => {
                guids.push(item.goods_guid);
            });
            that.$axios
                .post("/v1/activities/gift", {
                    id: that.id,
                    name: that.name,
                    phone: that.phone,
                    address: that.address,
                    goods_guid_set: guids.join(","),
                })
                .then((res) => {
                    let _res = res.data.data;
                    if (_res.code === 1000) {
                        that.page = 3;
                        // that.show_success = true
                    } else {
                        that.$toast({
                            message: _res.message ? _res.message : "操作失败",
                            duration: 2000,
                        });
                    }
                });
        },
    },
};
</script>
<style lang="scss">
#app {
    height: 100%;
}

.receive {
    color: #ffffff;
    overflow: hidden;
    min-height: 80%;
    padding-top: 1.5rem;

    &-item {
        padding: 0.3rem 0.5rem 0 0.5rem;
        margin-bottom: 0.2rem;

        h3 {
            opacity: 0.8;
            margin-bottom: 0.1rem;
        }
    }

    .van-steps {
        background: none;
    }
}

.gift-sets {
    position: relative;
    max-width: 7.5rem;
    min-height: 100vh;
    min-width: 100vw;
    // width: 100%;
    // height: 100%;
    // width: 100vw;
    // height: 100vh;
    margin: 0 auto;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    // position: absolute;
    // top: 0;
    // left: 0;

    &.receive-bg {
        .gift-sets-main {
            padding: 2rem 0.4rem 0.6rem;
        }
    }

    &-main {
        height: 100vh;
        overflow-y: auto;
        padding: 4.04rem 0.4rem 0.6rem;
        box-sizing: border-box;

        .gift-sets-title {
            font-size: 0.6rem;
            color: #ffffff;
            font-weight: normal;
            text-align: center;
            text-shadow: 0 0.14rem 12.48rem rgba(23, 21, 22, 0.56);
        }

        .list-box {
            margin-top: 0.36rem;

            .tab-box {
                display: flex;

                &-item {
                    flex: auto;
                    height: 0.62rpx;
                    text-align: center;
                    background-color: #ffaa9a;
                    margin-left: 0.05rem;
                    border-top-right-radius: 0.31rem;
                    border-top-left-radius: 0.31rem;

                    &:first-child {
                        margin-left: 0;
                    }

                    span {
                        font-size: 0.48rem;
                        color: #ffffff;
                    }

                    &.active {
                        background-color: #ffffff;

                        span {
                            color: #393939;
                        }
                    }
                }
            }

            .shop-box {
                background-color: #ffffff;

                .shop-list {
                    padding: 0.6rem 0.32rem;
                    box-sizing: border-box;

                    &-item {
                        .title {
                            text-align: center;
                            font-size: 0.36rem;
                            margin-bottom: 0.2rem;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            word-break: break-all;
                        }

                        img {
                            flex: none;
                            display: block;
                            width: 6.06rem;
                            height: 6.06rem;
                        }
                    }

                    &.item-2 {
                        .title {
                            text-align: center;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }

                        img {
                            width: 3.07rem;
                            height: 3.07rem;
                        }

                        .shop-list-item {
                            &:last-child {
                                margin-top: 0.8rem;
                            }
                        }
                    }

                    &.item-3 {
                        .shop-list-item {
                            margin-top: 0.3rem;

                            &:first-child {
                                margin-top: 0;
                            }
                        }

                        .shop-list-item-main {
                            display: flex;

                            .title {
                                margin-bottom: 0;
                                text-align: left;
                                -webkit-line-clamp: 6;
                            }

                            img {
                                margin-right: 0.32rem;
                                width: 2.85rem;
                                height: 2.85rem;
                            }
                        }
                    }

                    &.item-4 {
                        display: flex;
                        flex-wrap: wrap;
                        padding: 0.3rem 0.15rem;

                        .shop-list-item {
                            width: 2.86rem;
                            margin: 0.3rem 0.17rem;
                        }

                        img {
                            width: 2.86rem;
                            height: 2.86rem;
                        }

                        .title {
                            margin-bottom: 0;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }
                }

                .submit-btn {
                    display: block;
                    padding: 0 0 0.6rem;
                    text-align: center;

                    button {
                        display: inline-block;
                        width: 3.22rem;
                        height: 0.88rem;
                        background-color: #e5121a;
                        color: #ffffff;
                        font-size: 0.36rem;
                        border-radius: 0.44rem;
                    }
                }
            }
        }

        // 寄送确认
        .send-address {
            &-img {
                // color: #efca72;
                color: #ffffff;
                padding: 1.5rem 0.8rem 0 0.9rem;

                h4 {
                    margin-bottom: 0.2rem;
                }

                p {
                    font-size: 0.26rem;
                    padding-top: 0.1rem;
                }
            }

            // 轮播
            .my-swipe {
                margin: 0 auto;
                width: 3.14rem;
                border: 2px solid #ffffff;

                // height: 4rem;
                .van-swipe-item {
                    text-align: center;
                    background-color: #ffffff;

                    img {
                        display: block;
                        width: 100%;
                        // height: 100%;
                        // object-fit: cover;
                    }
                }
            }

            .meal-title {
                // width: 4rem;
                margin: 0.1rem auto 0;
                padding-bottom: 0.1rem;
                font-size: 0.36rem;
                color: #ffffff;
                text-align: center;
            }

            .meal-text {
                // width: 4rem;
                margin: 0.2rem auto 0;
                font-size: 0.28rem;
                color: #ffffff;
                text-align: center;

                line-height: 0.36rem;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            .send-tips {
                padding-top: 0.58rem;

                h3 {
                    font-size: 0.42rem;
                    color: #ffffff;
                    font-weight: normal;
                    text-align: center;
                }

                .from-table {
                    margin-top: 0.32rem;

                    input,
                    textarea {
                        display: block;
                        margin-top: 0.12rem;
                        width: 100%;
                        font-size: 0.28rem;
                        background-color: #ffffff;
                        outline: none;
                        border: 0;
                        box-sizing: border-box;
                    }

                    input {
                        height: 0.72rem;
                        padding: 0 0.2rem;
                    }

                    textarea {
                        padding: 0.12rem 0.2rem;
                    }
                }

                .send-btns {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 0.36rem;

                    button {
                        flex: 1;
                        // width: 3.2rem;
                        height: 0.88rem;
                        font-size: 0.36rem;
                        color: #ffffff;
                        border-radius: 0.4rem;
                        background-color: #e5121a;

                        &:first-child {
                            margin-right: 0.2rem;
                        }

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }

    // 寄送成功
    .send-success {
        width: 6.76rem;

        img {
            display: block;
            width: 100%;
        }
    }
}
</style>
